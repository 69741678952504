<template>
  <div class="container mt-4 mb-3 terms">
    <div class="row">
      <div class="col-12">
        <h1>Condições de Aluguer</h1>
        <h2>1 - OBJECTO</h2>
        <p>
          Lubrirent, Aluguer e Comércio de Automóveis, Sociedade Unipessoal Lda,
          adiante designada por locadora, aluga o veículo automóvel, melhor
          identificado nas condições particulares do contrato (adiante apenas
          Contrato), ao cliente identificado na cláusula primeira das condições
          particulares, adiante designado por locatário, nos seguintes termos e
          condições gerais.
        </p>
        <h2>2 – ENTREGA E DEVOLUÇÃO DO VEÍCULO</h2>
        <p>
          1 – O locatário declara que recebeu o veículo nas condições de
          utilização e limpeza, com os respectivos acessórios e documentos,
          mencionadas no contrato e no documento de verificação conjunta
          designado “check out”, comprometendo-se a devolvê-lo nas mesmas
          condições em que o recebeu, no local e data designados no contrato.<br />
          2 – Caso o veículo seja utilizado em violação do contrato, a locadora
          pode resolver o contrato, sendo obrigatória a devolução do veículo
          pelo locatário no local indicado, sob pena de o veículo lhe ser
          retirado, nos termos da lei, a expensas deste. <br />
          3 - Se o locatário desejar prolongar o período de aluguer deve
          dirigir-se às instalações da locadora para celebrar novo contrato,
          sujeito a aprovação. <br />
          4 – A locatária não é responsável perante o locatário ou qualquer
          passageiro pela perda ou por danos materiais deixados no veículo, quer
          durante o período de aluguer, quer após o mesmo.
        </p>
        <h2>3 – UTILIZAÇÃO DO VEÍCULO</h2>
        <p>
          1 – O locatário deve cuidar do veículo automóvel, assegurando-se que o
          mesmo fica devidamente fechado à chave e em local seguro quando não
          esteja a ser utilizado, colocar o combustível adequado, bem como ligar
          e utilizar com diligência qualquer dispositivo de segurança instalado
          no veículo, caso haja. <br> 2 - O locatário compromete-se a não utilizar ou
          a não permitir o uso do veículo nas seguintes situações: <br> a) Para
          efectuar transporte de passageiros ou mercadorias em violação da lei;
           b) Para provas desportivas ou treinos, quer estas sejam oficiais ou
          não; c) Por qualquer pessoa sob influência de álcool, drogas ou
          qualquer outra substância que, directa ou indirectamente, reduza a sua
          percepção e capacidade de reacção; d) Por pessoas detentoras de carta
          de condução há menos de um ano, e por pessoas que não sejam condutores
          autorizados, isto é, não estejam identificadas no contrato ou
          documento anexo ao mesmo; e) Fora do território português, salvo se
          tiver autorização expressa.
        </p>
        <h2>4 – MANUTENÇÃO E REPARAÇÃO DO VEÍCULO</h2>
        <p>
          No caso de o veículo sofrer alguma avaria, as reparações só podem ser efectuadas mediante acordo prévio com a locadora.
        </p>
        <h2>5 - SERVIÇOS</h2>
        <p>
          1 – O locatário pode contratar os seguintes serviços:
          <ol style="padding-left:25px">
            <li>
              CDW - Abrange todos os danos causados na viatura em caso de acidente, 
              estando o locatário sujeito ao pagamento de uma franquia variável em função do tipo de veículo e constante da tabela anexa ao contrato, 
              a qual faz parte integrante do mesmo; b) – TW – Abrange o furto e/ou roubo da viatura; 
            </li>
          </ol>
        </p>
        <p>
         2 - O locatário obriga-se, em caso de acidente, a ter os seguintes procedimentos: <br>
         a) Participar à locadora e às autoridades policiais todo e qualquer acidente, furto, roubo ou quaisquer outros sinistros, no prazo máximo de 24 horas; b) Obter os nomes e endereços das pessoas envolvidas e testemunhas; c) Não abandonar o veículo sem tomar as medidas adequadas com vista à protecção e salvaguarda do mesmo; d) Não assumir qualquer responsabilidade ou declarar-se culpado no caso de acidentes, que possam implicar responsabilidade da locadora; e) Contactar imediatamente à locadora fornecendo-lhe um relatório detalhado do acidente incluindo auto de acidente levantado pelas autoridades policiais.
         <br>
         3 - Apenas o locatário e/ou os condutores autorizados podem beneficiar das coberturas CDW 
         <br>
         4 - Mesmo no caso de o locatário subscrever a cobertura CDW, todos os danos decorrentes da má utilização do veículo, são da sua exclusiva responsabilidade.
         <br>
         5 - Em caso de acidente devido a excesso de velocidade, negligência, condução sob influência de álcool, produtos estupefacientes ou consumo de qualquer produto que diminua a capacidade de condução, será o locatário responsável pela totalidade das despesas da reparação e indemnização correspondente ao tempo de paralisação do veículo acidentado, mesmo que haja sido contratada a cobertura CDW.
         <br>
         6 - O veículo apenas terá a cobertura CDW durante o período acordado no contrato, excepto se houver prolongamento do contrato nos termos das presentes condições gerais, declinando desde já a locadora toda e qualquer responsabilidade pelos acidentes causados ou que possam vir a ser causados pelo locatário para além do período de aluguer, sendo este o único e exclusivo responsável pelos mesmos.
        </p>
        <h2>6- PAGAMENTOS</h2>
        <p>
          1 - O locatário obriga-se, expressamente, a pagar as importâncias devidas, e decorrentes da celebração do presente contrato, à locadora logo que lhe sejam solicitadas, nomeadamente:
          <br>
          a) O preço devido pelo aluguer do veículo, em função do período de aluguer e respectiva quilometragem calculada de acordo com a tarifa constante do contrato; b) Todos e quaisquer encargos referentes à supressão de franquia cobertura de acidentes pessoais, cobertura de choque, colisão e capotamento, cobertura de roubo e quaisquer outras despesas aplicáveis em conformidade com a tarifa ou taxas constantes das condições particulares do presente contrato; c) Todos os impostos e taxas incidentes sobre o aluguer do veículo automóvel ou o montante fixado pela locadora para reembolso desses impostos; d) Todos os custos suportados pela locadora emergentes da cobrança de pagamentos em dívida pelo locatário, em consequência do presente contrato. 
          <br>
          2 - Toda e qualquer factura não paga na data do vencimento será acrescida de juros de mora à taxa máxima legalmente permitida, 
          <br>
          3 – Em caso de acidente o locatário pagará, a título de despesas administrativas com o respectivo processo, 36,90€ (Trinta e Seis Euros e Noventa Cêntimos). 
          <br>
          4 - O locatário, para garantia do cumprimento das obrigações decorrentes do Contrato, prestará caução, em dinheiro, cheque ou débito em cartão de crédito, pelo montante referido no Contrato, autorizando expressamente a locadora a preencher e a debitar no cartão de crédito as importâncias devidas; a caução por danos no veículo, provocados ou não pelo locatário, será acionada após informação e prova dos danos em causa. 
        </p>
        <h2>7- DESPESAS ADMINISTRATIVAS</h2>
        <p>
          No caso de o locador ser notificado, em consequência de contra-ordenação ou conduta ilícita praticada pelo locatário, unicamente para identificar o mesmo, este obriga-se a pagar, a título de despesas administrativas, o montante de 36,90€ (Trinta e Seis Euros e Noventa Cêntimos) pela informação prestada àquelas entidades.
        </p>
        <h2>8 – DOMICÍLIO CONVENCIONADO</h2>
        <p>
          As partes convencionam as moradas indicadas no Contrato para qualquer contacto, nomeadamente, para efeitos de citações ou notificações.
        </p>
        <h2>9 – INFORMAÇÕES E ESCLARECIMENTOS</h2>
        <p>
          O locatário reconhece que todas as cláusulas constantes do presente contrato lhe foram atempada e expressamente comunicadas e explicadas e que o mesmo ficou ciente das mesmas, pelo que assina o presente contrato.
        </p>
        <h2>10– DADOS PESSOAIS</h2>
        <p>
          1 – O locatário fornece no início do Contrato os seus dados pessoais e os do(s) condutor(es) do veículo, para efeitos da respetiva identificação no âmbito do mesmo, autorizando expressamente a locadora a proceder ao tratamento informático dos mesmos.
          <br>
          2 – Nos termos do Regulamento (UE) 2016/679 do Parlamento Europeu e do Conselho de 27 de abril de 2016 (RGPD), a locadora informa o seguinte:
          <br>
          a) A entidade responsável pelo tratamento dos dados pessoais fornecidos no âmbito do contrato é a locadora Lubrirent, Aluguer e Comércio de Automóveis, Sociedade Unipessoal Lda, com sede em Rua Pinhal Cordeiro Carvoeiros, 2420 - 500 Leiria;
          <br>
          b) O tratamento dos dados pessoais tem como finalidade a celebração e execução do presente contrato, nos termos da alínea b) do n.º 1 do artigo 6.º do RGPD;
<br>
          c) Os dados pessoais podem ser transmitidos a terceiros com a finalidade de garantir o cumprimento de quaisquer obrigações legais a que a locadora se encontra sujeita, nos termos da alínea c) do n.º 1 do artigo 6.º do RGPD, nomeadamente a autoridades judiciais, órgãos de polícia criminal, autoridade tributária e aduaneira e entidades reguladoras;
<br>
          d) Os dados pessoais podem ser tratados para outras finalidades, para as quais o titular tenha dado consentimento expresso à locadora;
<br>
          e) A locadora conservará os dados pessoais tratados pelo período necessário à prestação dos serviços, respetiva faturação e cumprimento de obrigações jurídicas.
<br>
          3 – Em qualquer momento, o titular dos dados pessoais tem o direito de aceder aos mesmos, bem como, dentro dos limites do contrato e do RGPD, de os alterar, opor-se ao respetivo tratamento, decidir sobre o tratamento automatizado dos mesmos, retirar o consentimento, solicitar o apagamento dos dados e exercer os demais direitos previstos na legislação em vigor (salvo quanto aos dados que são indispensáveis à execução do contrato, e como tal sejam de fornecimento obrigatório, bem como para cumprimento de obrigações legais a que a locadora esteja sujeita).
<br>
          4 – Caso retire o seu consentimento, tal não compromete a licitude do tratamento efetuado até essa data.
<br>
          5 – O titular dos dados tem o direito de ser notificado, nos termos previstos no RGPD, caso ocorra uma violação dos seus dados pessoais suscetível de implicar um elevado risco para os direitos e liberdades, podendo apresentar reclamações perante a(s) autoridade(s).
<br>
          6 – Os dados pessoais podem ser transmitidos a entidades terceiras que prestem serviços à locadora, sempre que tais serviços impliquem a comunicação de dados constantes nos contratos de aluguer.
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "RentConditions",
  components: {},
  data: function () {
    return {};
  },
};
</script>
